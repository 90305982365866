.edit-banner-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.edit-banner-container h2 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.form-group input[type="text"],
.form-group textarea,
.form-group input[type="file"] {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    box-sizing: border-box;
}

textarea {
    height: 100px;
    resize: vertical;
}

.current-image img {
    max-width: 100%;
    height: auto;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

/* Scoped button styles for AdminEditBanner */
.edit-banner-container .btn {
    display: block;
    width: 100%;
    padding: 10px;
    background: #8B0000;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}

.edit-banner-container .btn:hover {
    background-color: #a83232;
}
