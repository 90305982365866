.cart-container {
  max-width: 1200px;
  margin: 2rem auto;
  padding: 1rem;
}

.cart-title {
  text-align: center;
  color: #8b0000;
  margin-bottom: 2rem;
  font-size: 2rem;
}

.empty-cart {
  text-align: center;
  color: #666;
}

.empty-cart button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #8b0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cart-content {
  display: flex;
  gap: 2rem;
}

.cart-items {
  flex: 2;
}

.cart-item {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 1rem;
}

.item-details {
  flex-grow: 1;
}

.item-name {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.item-size, .item-price {
  font-size: 0.9rem;
  color: #666;
}

.quantity-control {
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.quantity-btn {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background-color: #f0f0f0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.quantity-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.quantity-btn:active {
  background-color: #e0e0e0;
}

.quantity-control span {
  margin: 0 0.75rem;
  font-size: 1rem;
}

.remove-item {
  background: none;
  border: none;
  cursor: pointer;
  color: #ff4d4d;
  font-size: 1.2rem;
}

.cart-summary {
  flex: 1;
  background-color: #f8f9fa;
  padding: 1rem;
  border-radius: 5px;
}

.total-amount {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.phone-input input {
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
}

.checkout-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #8b0000;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.checkout-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .cart-content {
    flex-direction: column;
  }

  .cart-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-image {
    width: 100%;
    height: auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .remove-item {
    align-self: flex-end;
    margin-top: 1rem;
  }

  .cart-summary {
    margin-top: 2rem;
  }
}
