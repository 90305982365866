.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0,0,0,0.1);
    max-width: 400px;
    margin: 2rem auto;
}

.auth-container h2 {
    color: #333;
    margin-bottom: 1.5rem;
}

.auth-form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: #555;
}

.form-group input {
    width: 100%;
    padding: 0.75rem;
    border-radius: 4px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    font-size: 1rem;
}

.auth-button {
    padding: 0.75rem;
    border-radius: 4px;
    border: none;
    background-color: #000;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    margin-top: 1rem;
    transition: background-color 0.3s ease;
}

.auth-button:hover { 
    background-color: #333;
}

.auth-link {
    margin-top: 1rem;
    color: #666;
}

.auth-link a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
}

.auth-link a:hover {
    text-decoration: underline;
}