.search-results {
  margin-top: 20px;
}

.search-results h3 {
  margin-top: 15px;
  margin-bottom: 10px;
}

.search-results ul {
  list-style-type: none;
  padding: 0;
}

.search-results li {
  cursor: pointer;
  padding: 5px 0;
  border-bottom: 1px solid #eee;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.search-overlay {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
}

.search-overlay-content {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  margin-top: 80px;
}

.close-search {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.search-input-wrapper {
  position: relative;
}

.search-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  color: #888;
}

.search-input {
  width: 100%;
  padding: 10px 10px 10px 40px;
  font-size: 18px;
  border: none;
  border-bottom: 2px solid #000;
  outline: none;
}
