/* src/components/UserProfile.css */
.user-profile-container {
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #000;
    background-color: #fff;
}

.user-profile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.user-profile-tabs {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    border-bottom: 2px solid #8b0000;
}

.tab-button {
    background-color: transparent;
    color: #000;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.tab-button.active {
    background-color: #8b0000;
    color: #fff;
}

.user-profile-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.profile-edit-form input,
.address-form input,
.payment-form input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

button {
    padding: 12px 24px;
    background-color: #8b0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #6d0000;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

th, td {
    border: 1px solid #ddd;
    padding: 12px;
    text-align: left;
}

th {
    background-color: #f2f2f2;
    font-weight: bold;
}

@media (max-width: 768px) {
    .user-profile-tabs {
        flex-wrap: wrap;
    }

    .tab-button {
        flex: 1 0 50%;
    }

    table, thead, tbody, th, td, tr {
        display: block;
    }

    thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    tr {
        margin-bottom: 15px;
    }

    td {
        border: none;
        position: relative;
        padding-left: 50%;
    }

    td:before {
        content: attr(data-label);
        position: absolute;
        left: 6px;
        width: 45%;
        padding-right: 10px;
        white-space: nowrap;
        font-weight: bold;
    }
}

.form-container {
    background-color: #f8f8f8;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-title {
    font-size: 18px;
    margin-bottom: 15px;
    color: #333;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #333;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.form-group input:focus {
    border-color: #000;
    outline: none;
}

.button-group {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s, color 0.3s;
}

.button-primary {
    background-color: #000;
    color: #fff;
}

.button-primary:hover {
    background-color: #333;
}

.button-secondary {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
    margin-right: 10px;
}

.button-secondary:hover {
    background-color: #f0f0f0;
}
