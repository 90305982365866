.order-confirmation-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f2f4f8;
}

.order-confirmation-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    max-width: 500px;
    width: 100%;
}

.order-confirmation-title {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.order-confirmation-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.success-icon {
    width: 4rem;
    height: 4rem;
    color: #10b981;
}

.confirmation-message {
    text-align: center;
    color: #4b5563;
}

.order-details {
    margin-top: 2rem;
}

.order-details h2 {
    font-size: 1rem;
    font-weight: 600;
    color: #4b5563;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.order-item {
    color: #6b7280;
}

.item-quantity {
    background-color: #e5e7eb;
    padding: 0.25rem 0.5rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    margin-left: 0.5rem;
}

.total-amount {
    font-weight: bold;
    color: #111827;
}

.delivery-address {
    color: #6b7280;
}

.redirect-message {
    text-align: center;
    margin-top: 2rem;
    color: #6b7280;
    font-style: italic;
}

.return-home-button {
    background-color: #4CAF50;
    border: none;
    color: white;
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
}

.return-home-button:hover {
    background-color: #45a049;
}