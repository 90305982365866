#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1 0 auto;
}
a, a:hover, a:focus, a:active {
  text-decoration: none;
  color: inherit;
}
