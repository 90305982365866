.collection-container {
    padding: 40px 0;
    background-color: #f5f5f5;
}

.section-title {
    text-align: center;
    margin-bottom: 40px;
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
}

.collection-products-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(400px, 1fr)); /* Sütun genişliğini artırdık */
    gap: 2rem;
    padding: 2rem 0;
}

.collection-product-card {
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
    overflow: hidden;
    height: 500px; /* Yüksekliği artırdık */
    display: flex;
    flex-direction: column;
    position: relative;
}

.collection-product-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0,0,0,0.2);
}

.collection-product-image-container {
    position: relative;
    width: 100%;
    padding-top: 100%; /* 1:1 en boy oranı için */
    overflow: hidden;
}

.collection-product-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.collection-product-card:hover .collection-product-image {
    transform: scale(1.05);
}

.collection-product-content {
    padding: 20px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.collection-product-name {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.collection-product-description {
    font-size: 0.9rem;
    color: #666;
    margin-bottom: 10px;
    display: -webkit-box;

    -webkit-box-orient: vertical;
    overflow: hidden;
}

.collection-product-price {
    font-size: 1.3rem;
    font-weight: bold;
    color: #0f0f0f;
    margin-top: 5px;
    
}

.add-to-cart-button {
    width: 100%;
    padding: 10px;
    background-color: #050505;
    color: white;
    border: none;
    border-radius: 0 0 8px 8px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-to-cart-button:hover {
    background-color: #0a0a0a;
}

@media (max-width: 768px) {
    .section-title {
        font-size: 2rem;
    }

    .collection-products-grid {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 20px;
    }

    .collection-product-card {
        height: 380px;
    }

    .collection-product-image-container {
        height: 200px;
    }
}

@media (max-width: 480px) {
    .section-title {
        font-size: 1.8rem;
    }

    .collection-products-grid {
        grid-template-columns: 1fr;
    }
}
