.customer-container {
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

.customer-table-container {
    width: 100%;
    overflow-x: auto;
}

.customer-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.customer-table th, .customer-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

.customer-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}
