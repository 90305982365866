.admin-order-list {
    padding: 20px;
}

.admin-order-list h2 {
    text-align: center;
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
}

table th, table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

table tbody tr:hover {
    background-color: #f1f1f1;
}

.order-row {
    cursor: pointer;
}

.order-row:hover {
    background-color: #f1f1f1;  /* Hover üzerinde arka plan rengi değişimi */
}

.order-item {
    display: flex;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
}

.order-item-image {
    max-width: 100px;
    max-height: 100px;
    margin-right: 20px;
}

.order-item-details {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
