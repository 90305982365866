/* Kategori kartı düzeni */
.category-section {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

/* Kategori kartları */
.category-card {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 2rem;
    width: 100%;
    max-width: 1200px;
}

.category-card.right {
    flex-direction: row-reverse;
}

/* Büyük koleksiyon resmi */
.category-image-wrapper {
    flex: 0 0 55%; /* Büyük resim alanını %55'e düşürdük */
    max-width: 700px; /* Maksimum genişlik */
    cursor: pointer; /* Resme tıklanabilirlik için pointer eklendi */
}

.category-image {
    width: 100%;
    height: auto;
    display: block;
    max-height: 500px;
    object-fit: cover;
}

/* Küçük ürünler alanı */
.category-content {
    flex: 0 0 40%; /* Küçük ürünler için alan */
    padding-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Küçük ürün kartlarının sıralanması */
.small-products-preview {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    gap: 1.5rem; /* Ürünler arası boşluk artırıldı */
    flex-wrap: nowrap;
}

/* Küçük ürün kartı */
.small-product-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 48%; /* Ürün genişliği büyütüldü */
    margin-bottom: 1rem;
}

.product-thumbnail-image-wrapper {
    width: 100%;
    height: 250px;
    overflow: hidden;
}

.small-thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-thumbnail-details {
    text-align: center;
    margin-top: 0.5rem;
}

.product-price {
    font-size: 1.3rem;
    color: #ff4c4c;
    margin-top: 0.5rem;
}
.product-price {
    font-size: 1.2rem; /* Font büyüklüğü */
    color: #ff4c4c; /* Kırmızı renk */
    font-weight: bold; /* Kalın yazı */
    margin-top: 0.5rem;
}


/* Mobil görünüm */
@media (max-width: 768px) {
    .category-card {
        flex-direction: column;
    }

    .category-card.right {
        flex-direction: column;
    }

    .category-image-wrapper,
    .category-content {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .category-image {
        max-height: 300px;
    }

    .small-products-preview {
        flex-direction: row;
        justify-content: center;
        gap: 1rem;
    }

    .small-product-thumbnail {
        width: 48%;
    }

    .product-thumbnail-image-wrapper {
        height: auto;
    }
}
