.dashboard {
    padding: 20px;
    background-color: #f5f5f5;
    color: #333;
}

.dashboard-header {
    text-align: center;
    margin-bottom: 30px;
}

.dashboard-title {
    font-size: 2.5rem;
    color: #000;
    margin-bottom: 10px;
}

.dashboard-subtitle {
    font-size: 1.2rem;
    color: #333;
}

.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.card {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.card-icon {
    font-size: 2.5rem;
    margin-right: 15px;
    color: #000;
}

.card-content h3 {
    font-size: 1.2rem;
    margin-bottom: 5px;
    color: #000;
}

.card-content p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #000;
}

.dashboard-charts {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
}

.chart-container, .dashboard-table {
    background: #ffffff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-x: auto;
}

.chart-title, .table-title {
    font-size: 18px;
    color: #333;
    margin-bottom: 20px;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
}

th {
    font-weight: bold;
    color: #333;
    background-color: #f9f9f9;
}

.status {
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    font-weight: bold;
}

.status.tamamlandı {
    background-color: #e6f7ed;
    color: #0e6245;
}

.status.kargoda {
    background-color: #e6f0ff;
    color: #0052cc;
}

.status.işleniyor {
    background-color: #fff7e6;
    color: #b37400;
}

@media (max-width: 768px) {
    .dashboard-cards {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    }

    .dashboard-charts {
        grid-template-columns: 1fr;
    }

    .card {
        flex-direction: column;
        text-align: center;
    }

    .card-icon {
        margin-right: 0;
        margin-bottom: 10px;
    }

    .chart-container, .dashboard-table {
        padding: 15px;
    }

    table {
        font-size: 14px;
    }

    th, td {
        padding: 8px;
    }
}
