html, body {
    height: 100%;
    margin: 0;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.footer {
    background-color: #000000;
    color: #ffffff;
    padding: 40px 0 20px;
    margin-top: auto;
}

.footer-title {
    color: #8B0000; /* Koyu bordo */
    margin-bottom: 20px !important;
    font-weight: bold !important;
}

.footer-links {
    list-style: none;
    padding: 0;
}

.footer-links li {
    margin-bottom: 10px;
}

.footer-links a {
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s ease;
}

.footer-links a:hover {
    color: #8B0000; /* Koyu bordo */
}

.newsletter-form {
    margin-top: 20px;
}

.newsletter-form .MuiTextField-root {
    background-color: #ffffff;
    border-radius: 4px;
}

.newsletter-form .MuiTextField-root input {
    color: #000000;
}

.newsletter-form .MuiButton-root {
    margin-top: 10px;
    background-color: #8B0000; /* Koyu bordo */
    color: #ffffff;
    font-weight: bold;
}

.newsletter-form .MuiButton-root:hover {
    background-color: #660000; /* Daha koyu bordo */
}

.footer-bottom {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyright {
    font-size: 0.9rem;
    color: #ffffff;
}

.social-icons .MuiIconButton-root {
    color: #ffffff;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.social-icons .MuiIconButton-root:hover {
    color: #8B0000; /* Koyu bordo */
}

@media (max-width: 600px) {
    .footer {
        padding: 30px 0 20px;
    }

    .footer-bottom {
        flex-direction: column;
        align-items: center;
    }

    .copyright {
        margin-bottom: 20px;
        text-align: center;
    }
}
