/* General Navbar Styling */
.top-info-bar {
  background-color: #f4f4f4;
  color: #000;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
}

.main-navbar {
  background-color: #fff;
  border-bottom: 1px solid #e0e0e0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 20px;
}

.navbar-left, .navbar-right {
  display: flex;
  align-items: center;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.logo img {
  height: 30px;
}

.nav-items {
  display: flex;
  gap: 20px;
}

.nav-items a {
  text-decoration: none;
  color: #000;
  font-weight: bold;
  font-size: 14px;
  text-transform: uppercase;
}

.icon-link {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #000;
  font-size: 18px;
  margin-left: 20px;
  text-decoration: none;
}

.icon-link span {
  font-size: 12px;
  margin-top: 5px;
}

.search-bar {
  display: flex;
  align-items: center;
}

.search-bar input {
  padding: 5px 10px;
  border: 1px solid #e0e0e0;
  border-right: none;
}

.search-bar button {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-left: none;
  padding: 5px 10px;
  cursor: pointer;
}

.icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icon {
  font-size: 18px;
  color: #000;
  cursor: pointer;
}

.admin-button,
.login-button,
.signup-button {
  background-color: #fff;
  color: #000;
  border: 1px solid #000;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
}

.language-selection {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  position: relative;
}

.language-dropdown {
  display: none;
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 5px 0;
}

.language-selection:hover .language-dropdown {
  display: block;
}

.language-dropdown a {
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  color: #000;
}

/* Mobile styles */
.mobile-menu-toggle, .mobile-search-icon {
  display: none;
  font-size: 24px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .top-info-bar {
    display: none;
  }

  .navbar-content {
    justify-content: space-between;
    padding: 10px;
  }

  .mobile-menu-toggle, .mobile-search-icon {
    display: block;
    margin-right: 15px;
  }

  .navbar-left {
    display: flex;
    align-items: center;
  }

  .nav-items {
    display: none;
  }

  .logo {
    position: static;
    transform: none;
  }

  .navbar-right {
    display: flex;
  }

  .navbar-right .icon-link {
    margin-left: 15px;
  }

  .desktop-only {
    display: none;
  }

  .icon-link span {
    display: none;
  }
}

/* Mobile Menu Styles */
.mobile-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 80%;
  max-width: 300px;
  height: 100vh;
  background-color: #fff;
  z-index: 1000;
  transition: left 0.3s ease-in-out;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.mobile-menu.open {
  left: 0;
}

.close-menu {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.mobile-menu-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.mobile-menu-top {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
}

.mobile-menu-top a,
.mobile-menu-top button {
  padding: 10px;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  text-transform: uppercase;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.mobile-menu-items {
  padding: 10px;
}

.mobile-menu-items h3 {
  font-size: 18px;
  margin-bottom: 10px;
}

.mobile-menu-items a {
  display: block;
  padding: 10px 0;
  text-decoration: none;
  color: #000;
  font-size: 14px;
}

/* Overlay for mobile menu */
.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.mobile-menu-overlay.open {
  display: block;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-toggle {
  font-size: 16px;
  padding: 10px;
  font-weight: bold ;
  cursor: default;
}

.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  padding: 20px;
  z-index: 1000;
  width: 800px;
}

.dropdown:hover .dropdown-menu {
  display: flex;
}

.dropdown-column {
  flex: 1;
  padding: 0 15px;
}

.dropdown-column h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.dropdown-column a {
  display: block;
  padding: 5px 0;
  font-size: 14px;
  color: #333;
  text-decoration: none;
}

.dropdown-column a:hover {
  text-decoration: underline;
}

.admin-button {
  background-color: #000;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  text-decoration: none;
  font-size: 14px;
  margin-left: 15px;
}

/* Overlay for mobile menu */
.mobile-menu-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.mobile-menu-overlay.open {
  display: block;
}

.mobile-nav-items {
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.mobile-nav-items a {
  padding: 10px 0;
  font-size: 18px;
  color: #333;
  text-decoration: none;
  border-bottom: 1px solid #eee;
}

.mobile-account-links {
  margin-top: 20px;
}

.mobile-account-links a,
.mobile-account-links button {
  display: block;
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #333;
  text-decoration: none;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.mobile-admin-link {
  margin-top: 20px;
  color: #007bff !important;
}
/* Mobile menu styles */
.mobile-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.95);
  color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.mobile-menu nav ul {
  list-style: none;
  padding: 0;
  width: 100%;
}

.mobile-menu nav ul li {
  margin-bottom: 20px;
  text-align: center;
}

.mobile-menu nav ul li a {
  color: black;
  font-size: 1.5rem;
  text-decoration: none;
  width: 100%;
  display: block;
  padding: 10px 0;
  border-bottom: 1px solid #ddd;
}

.mobile-menu nav ul li a:hover {
  background-color: #f0f0f0;
}

.close-menu {
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  color: black;
  font-size: 2rem;
  cursor: pointer;
}
