.product-detail-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
}

.product-detail {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
}

.product-images {
    flex: 0 0 50%;
    max-width: 50%;
}

.main-image {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    cursor: zoom-in;
}

.main-image img {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease;
}

.main-image.zoomed img {
    transform: scale(2);
    transform-origin: var(--x) var(--y);
}

.thumbnail-images {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    gap: 10px;
}

.thumbnail-images img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    border: 2px solid transparent;
}

.thumbnail-images img.active,
.thumbnail-images img:hover {
    border-color: #000;
    transform: scale(1.1);
}

.product-info {
    flex: 0 0 45%;
    max-width: 100%;
    text-align: left;
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
}

.product-info h1 {
    font-size: 2.2rem;
    margin-bottom: 15px;
    color: #333;
}

.product-info .price {
    font-size: 2rem;
    font-weight: bold;
    color: #8b0000;
    margin-bottom: 25px;
    text-align: left;
}

.selection-container {
    margin-bottom: 30px;
}

.size-selection,
.color-selection {
    margin-bottom: 20px;
}

.product-info h3 {
    font-size: 1.2rem;
    margin-bottom: 12px;
    color: #333;
}

.sizes, .colors {
    display: flex;
    gap: 12px;
    flex-wrap: wrap;
}

.sizes span, .colors span {
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 25px;
    cursor: pointer;
    transition: all 0.3s;
    font-size: 1rem;
    min-width: 45px;
    text-align: center;
}

.sizes span.size-selected, .colors span.color-selected {
    background-color: #333;
    color: #fff;
    border-color: #333;
}

.add-to-cart {
    width: 100%;
    background-color: #8b0000;
    color: #fff;
    padding: 15px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease;
    margin-bottom: 25px;
}

.add-to-cart:hover {
    background-color: #c0392b;
}

.product-description {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
}

.description-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-bottom: 15px;
}

.description-header h3 {
    margin: 0;
    font-size: 1.2rem;
    color: #333;
}

.description-header span {
    font-size: 1.3rem;
    color: #333;
}

.product-description p {
    margin-top: 12px;
    line-height: 1.7;
    color: #555;
}

.heart-button {
    position: absolute;
    top: 15px;
    right: 15px;
    background: rgba(255, 255, 255, 0.9);
    border: none;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.heart-button:hover {
    background: #fff;
    transform: scale(1.1);
}

.heart-button .filled {
    color: #e74c3c;
}

.heart-button .unfilled {
    color: #555;
}


.main-image img {
    transition: transform 0.3s ease-in-out;
}

.main-image.zoomed img {
    transform: scale(2);
    transform-origin: var(--x) var(--y);
}
.add-to-cart {
    width: 100%;
    background-color: #8b0000;
    color: #fff;
    padding: 15px 20px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-size: 1.2rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.3s ease; /* Add transition for smooth effect */
    margin-bottom: 25px;
}

.add-to-cart:hover {
    background-color: #c0392b;
}

.add-to-cart.added-to-cart {
    background-color: #28a745; /* Change background to green */
    transform: scale(1.1); /* Add a bounce effect */
    color: #fff; /* Keep text white */
}

.add-to-cart.added-to-cart:after {
    content: '✓ Sepete Eklendi'; /* Add confirmation text */
    display: inline-block;
    margin-left: 10px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .product-detail-container {
        padding: 0;
        background-color: transparent;
    }

    .product-detail {
        flex-direction: column;
        gap: 0;
    }

    .product-images,
    .product-info {
        max-width: 100%;
        flex: 0 0 100%;
        width: 100%;
    }

    .product-images {
        margin-bottom: 0;
    }

    .main-image {
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        border-radius: 0;
    }

    .main-image img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .thumbnail-images {
        justify-content: center;
        padding: 10px 0;
        background-color: #fff;
    }

    .product-info {
        padding: 20px;
        border-radius: 0;
        box-shadow: none;
        background-color: #fff;
    }

    .product-info h1 {
        font-size: 1.8rem;
    }

    .product-info .price {
        font-size: 1.6rem;
    }

    .sizes span, .colors span {
        padding: 8px 12px;
        font-size: 0.9rem;
        min-width: 40px;
    }

    .add-to-cart {
        padding: 12px 15px;
        font-size: 1.1rem;
        border-radius: 0;
    }

    .product-description {
        margin-top: 0;
        border-radius: 0;
    }

    .description-header h3 {
        font-size: 1.1rem;
    }

    .product-description p {
        font-size: 0.9rem;
    }
}

/* ... benzer ürünler için mobil uyumluluk ... */