/* AdminAddBanner.css */
.add-banner-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.add-banner-container h2 {
    margin-bottom: 20px;
    text-align: center;
}

.add-banner-container .form-group {
    margin-bottom: 15px;
}

.add-banner-container .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.add-banner-container .form-group input,
.add-banner-container .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.add-banner-container button {
    display: block;
    width: 100%;
    padding: 10px;
    background: #3B71CA;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
}
