.admin-product-list {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h2 {
    font-size: 24px;
    color: #333;
}

.add-product-btn {
    background-color: #8B0000;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
    transition: background-color 0.3s ease;
}

.add-product-btn:hover {
    background-color: #6B0000;
}

.product-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 1px 3px rgba(0,0,0,0.2);
    background-color: white;
}

.product-table th,
.product-table td {
    padding: 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
    vertical-align: middle;
}

.product-table th {
    background-color: #f2f2f2;
    font-weight: bold;
    color: #333;
}

.product-table tr:hover {
    background-color: #f5f5f5;
}

.product-image-cell {
    width: 100px;
}

.product-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.action-btn {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
    transition: color 0.3s ease;
    padding: 5px;
}

.view-btn { color: #4CAF50; }
.edit-btn { color: #2196F3; }
.delete-btn { color: #f44336; }

.action-btn:hover {
    opacity: 0.7;
}

.mobile-product-list {
    display: none;
}

@media (max-width: 768px) {
    .product-table {
        display: none;
    }

    .mobile-product-list {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 20px;
    }

    .mobile-product-card {
        background-color: white;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        overflow: hidden;
    }

    .mobile-product-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    .mobile-product-info {
        padding: 15px;
    }

    .mobile-product-info h3 {
        margin: 0 0 10px;
        font-size: 18px;
        color: #333;
    }

    .mobile-product-info p {
        margin: 5px 0;
        color: #666;
    }

    .mobile-product-actions {
        display: flex;
        justify-content: space-around;
        padding: 10px;
        background-color: #f2f2f2;
    }

    .mobile-product-actions .action-btn {
        font-size: 20px;
    }
}
