.heart-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: rgba(255, 255, 255, 0.7);
    border: none;
    cursor: pointer;
    outline: none;
    padding: 8px;
    margin: 0;
    border-radius: 50%;
    transition: all 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    z-index: 10;
}

.heart-button:hover {
    background: rgba(255, 255, 255, 0.9);
    transform: scale(1.1);
}

.heart-icon {
    width: 24px;
    height: 24px;
    transition: all 0.3s ease;
    fill: #888;
}

.heart-button.favorite .heart-icon {
    fill: #ff4d4d;
    animation: pulse 0.3s ease;
}

.heart-button:focus {
    outline: none;
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

@media (max-width: 768px) {
    .heart-button {
        width: 36px;
        height: 36px;
    }

    .heart-icon {
        width: 20px;
        height: 20px;
    }
}
