.cookie-banner {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    z-index: 1000;
}

.cookie-banner p {
    display: inline;
    margin-right: 10px;
}

.cookie-banner button {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
}
