.banner-list-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.banner-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 16px;
}

.banner-table th, 
.banner-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.banner-table th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.banner-image {
    width: 100px;
    height: 60px;
    object-fit: cover;
    border-radius: 4px;
}

.banner-actions .icon {
    font-size: 18px;
    margin-right: 10px;
    cursor: pointer;
    transition: transform 0.2s ease;
}

.edit-banner-btn {
    color: #FFD700;
}

.delete-banner-btn {
    color: #FF6347;
}

.icon:hover {
    transform: scale(1.2);
}
