.favorites-container {
    padding: 40px 20px;
    max-width: 1400px;
    margin: 0 auto;
}

.favorites-header {
    text-align: center;
    margin-bottom: 40px;
    position: relative;
}

.favorites-title {
    font-size: 2.5rem;
    color: #333;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-bottom: 10px;
}

.favorites-underline {
    width: 100px;
    height: 4px;
    background-color: #8b0000;
    margin: 0 auto;
}

.favorites-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
    margin-bottom: 40px;
}

.favorite-item {
    margin-bottom: 30px;
}

@media (max-width: 1200px) {
    .favorites-container {
        max-width: 1140px;
    }
}

@media (max-width: 992px) {
    .favorites-container {
        max-width: 960px;
    }
}

@media (max-width: 768px) {
    .favorites-title {
        font-size: 2rem;
    }
    .favorites-container {
        max-width: 720px;
    }
}

@media (max-width: 576px) {
    .favorites-container {
        max-width: 540px;
    }
}
