.collection-list-container {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
.collection-list-container .btn {
    padding: 10px 20px;
    background-color: #FFD700; /* Yellow for edit */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    margin-right: 5px;
    color: white;
}
.collection-list-container .btn.delete {
    background-color: #FF6347; /* Red for delete */
}

.collection-list-container .btn:hover {
    opacity: 0.9; /* Hover effect */
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.header h2 {
    font-size: 24px;
    color: #333;
}

.add-collection-btn {
    background-color: #8B0000;
    color: white;
    padding: 12px 24px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.add-collection-btn:hover {
    background-color: #600000;
}

.collection-table {
    width: 100%;
    border-collapse: collapse;
    text-align: left;
    font-size: 16px;
}

.collection-table th, 
.collection-table td {
    padding: 12px;
    border-bottom: 1px solid #ddd;
}

.collection-table th {
    background-color: #f1f1f1;
    font-weight: bold;
}

.collection-image {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 4px;
}

.collection-actions .icon {
    font-size: 24px;
    cursor: pointer;
    transition: color 0.3s ease, transform 0.2s ease;
}

.edit-collection-btn {
    color: #FFD700;
}

.delete-collection-btn {
    color: #FF6347;
}

.icon:hover {
    transform: scale(1.2);
}
