.create-product-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 30px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.create-product-container h2 {
    margin-bottom: 30px;
    text-align: center;
    color: #333;
}

.form-row {
    display: flex;
    gap: 20px;
}

.form-group {
    margin-bottom: 20px;
    flex: 1;
}

.form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #555;
}

.form-group input[type="text"],
.form-group input[type="number"],
.form-group textarea,
.form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s;
}

.form-group input[type="text"]:focus,
.form-group input[type="number"]:focus,
.form-group textarea:focus,
.form-group select:focus {
    border-color: #8B0000;
    outline: none;
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

.new-color-input {
    display: flex;
    margin-top: 10px;
}

.new-color-input input {
    flex-grow: 1;
    margin-right: 10px;
}

.new-color-input button {
    background-color: #8B0000;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.image-preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-bottom: 15px;
}

.image-preview-wrapper {
    position: relative;
    width: 120px;
    height: 120px;
}

.current-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    border: 2px solid #ddd;
    transition: border-color 0.3s;
}

.current-image:hover {
    border-color: #8B0000;
}

.main-image {
    border-color: #8B0000;
}

.remove-image-btn {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.3s;
}

.remove-image-btn:hover {
    background-color: rgba(255, 0, 0, 1);
}

.main-image-label {
    position: absolute;
    bottom: 5px;
    left: 5px;
    background-color: rgba(139, 0, 0, 0.7);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.btn {
    display: inline-block;
    padding: 12px 24px;
    background-color: #8B0000;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.btn:hover {
    background-color: #6B0000;
}

@media (max-width: 768px) {
    .form-row {
        flex-direction: column;
    }

    .create-product-container {
        padding: 20px;
    }
}