.similar-products {
    margin-top: 40px;
    text-align: center;
    padding: 0 20px;
}

.similar-products h2 {
    margin-bottom: 20px;
}

.similar-products-container {
    max-width: 1200px;
    margin: 0 auto;
    overflow: hidden;
}

.similar-products-grid {
    display: flex;
    justify-content: center;
    overflow-x: auto;
    scroll-behavior: smooth;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    gap: 20px;
    padding: 10px 0;
    -webkit-overflow-scrolling: touch; /* iOS momentum scrolling */
}

.similar-products-grid::-webkit-scrollbar {
    display: none; /* WebKit */
}

.similar-product-card {
    flex: 0 0 auto;
    width: calc(25% - 15px);
    max-width: 280px;
    min-width: 200px;
    text-decoration: none;
    color: inherit;
    transition: transform 0.3s ease;
}

.similar-product-card:hover {
    transform: translateY(-5px);
}

.similar-product-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
}

.similar-product-card h3 {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}

.similar-product-card p {
    margin-top: 5px;
    font-weight: bold;
}

@media (max-width: 768px) {
    .similar-products {
        margin-top: 20px;
    }

    .similar-products h2 {
        font-size: 1.5rem;
        margin-bottom: 15px;
    }

    .similar-product-card {
        min-width: 150px;
    }

    .similar-product-card img {
        height: 150px;
    }

    .similar-product-card h3 {
        font-size: 14px;
    }

    .similar-product-card p {
        font-size: 12px;
    }
}