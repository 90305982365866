.product-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    transition: transform 0.3s ease-in-out;
}

.product-card:hover {
    transform: translateY(-5px);
}

.card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.product-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
    color: #8B0000; /* Bordo renk */
}

.product-price {
    color: #8B0000; /* Bordo renk */
    font-weight: bold;
}

.favorite-button {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: rgba(255, 255, 255, 0.7) !important;
}

.favorite-button:hover {
    background-color: rgba(255, 255, 255, 1) !important;
}
.product-info h3 {
    font-size: 1.2rem;
    margin-bottom: 15px;
    color: #8B0000;
}

.product-info Typography {
    color: #8b0000 !important;
}
.home-page .product-info p {
    color: #8B0000;
}
.product-description {
    color: black;
}