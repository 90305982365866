.admin-sidebar {
    width: 250px;
    background: #f4f4f4;
    color: #333;
    height: 100vh;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transition: all 0.3s ease;
    overflow-y: auto;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.admin-sidebar.collapsed {
    width: 60px;
}

.sidebar-logo {
    padding: 20px;
    text-align: center;
}

.sidebar-logo img {
    max-width: 100%;
    height: auto;
    transition: all 0.3s ease;
}

.admin-sidebar.collapsed .sidebar-logo img {
    max-width: 40px;
}

.sidebar-links {
    margin-top: 20px;
}

.sidebar-link {
    color: #333;
    padding: 12px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    white-space: nowrap;
    overflow: hidden;
}

.sidebar-link:hover, .sidebar-link.active {
    background: #e0e0e0;
    color: #1a1a1a;
}

.sidebar-link svg {
    margin-right: 10px;
    font-size: 18px;
    min-width: 18px;
}

.admin-sidebar.collapsed .sidebar-link span {
    opacity: 0;
    width: 0;
    transition: opacity 0.3s ease, width 0.3s ease;
}

.admin-sidebar.collapsed .sidebar-link svg {
    margin-right: 0;
}

.sidebar-footer {
    margin-top: auto;
    padding: 20px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.mobile-top-bar {
    display: none;
}

@media (max-width: 768px) {
    .mobile-top-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #f4f4f4;
        padding: 10px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1002;
        height: 50px;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    }

    .sidebar-toggle {
        background: none;
        border: none;
        color: #333;
        font-size: 24px;
        cursor: pointer;
    }

    .user-profile {
        width: 30px;
        height: 30px;
        background: #ddd;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .admin-sidebar {
        position: fixed;
        top: 50px;
        left: -250px;
        width: 250px;
        height: calc(100vh - 50px);
        transition: left 0.3s ease;
        z-index: 1001;
    }

    .admin-sidebar.open {
        left: 0;
    }

    .admin-sidebar.mobile:not(.collapsed) {
        transform: translateX(0);
    }

    .admin-sidebar.collapsed {
        transform: translateX(-100%);
    }

    .sidebar-logo {
        padding: 10px;
    }

    .sidebar-link {
        padding: 8px 15px;
        font-size: 14px;
    }

    .sidebar-link svg {
        font-size: 18px;
        min-width: 18px;
    }

    .sidebar-footer {
        padding: 10px;
        font-size: 14px;
    }
}
