/* Carousel container */
.carousel-container {
    width: 100%;
    max-width: 90%;
    margin: 0 auto;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

/* Carousel images */
.carousel-item {
    height: 70vh;
}

.carousel-item img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

/* Center the carousel captions */
.carousel-caption {
    background: rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center; /* Center the text */
    color: white;
    max-width: 60%;
}

/* Caption title and description styling */
.carousel-caption h3 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    text-align: center;
}

.carousel-caption p {
    font-size: 1.2rem;
    text-align: center;
}

/* Custom left and right arrows */
.custom-arrow-left,
.custom-arrow-right {
    width: 40px;
    height: 40px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 10;
}

.custom-arrow-left::before,
.custom-arrow-right::before {
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border-top: 2px solid black;
    border-right: 2px solid black;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
}

.custom-arrow-left::before {
    transform: translate(-50%, -50%) rotate(-135deg);
}

.custom-arrow-left {
    left: 10px;
}

.custom-arrow-right {
    right: 10px;
}

.custom-arrow-left:hover,
.custom-arrow-right:hover {
    background-color: rgba(255, 255, 255, 0.9);
    transform: scale(1.1) translateY(-50%);
}
/* Small and styled indicator dots */
.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease;
    border-radius: 50%;
}
.carousel-indicators [data-bs-target] {
    width: 10px;
    height: 10px;
    background-color: #fff; /* Default color for non-active indicators */
    border-radius: 50%;
    margin: 0 5px;
    border: none;
    opacity: 0.5;
    transition: background-color 0.3s ease, opacity 0.3s ease;
}

/* Active indicator styles */
.carousel-indicators .active {
    background-color: #000; /* Black for the active indicator */
    opacity: 1; /* Make the active indicator fully opaque */
}

/* Remove outline when clicked or focused */
.carousel-indicators [data-bs-target]:focus {
    outline: none;
}

.carousel-indicators li {
    width: 8px;
    height: 8px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    margin: 0 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.carousel-indicators li:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.carousel-indicators .active {
    background-color: #ffffff;
    transform: scale(1.2);
}
/* Hide Previous/Next text */
.carousel-control-prev .visually-hidden,
.carousel-control-next .visually-hidden {
    display: none !important;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
    .carousel-container {
        max-width: 100%;
    }

    .carousel-caption {
        bottom: 10%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 1rem;
        max-width: 90%;
    }

    .carousel-caption h3 {
        font-size: 1.8rem;
    }

    .carousel-caption p {
        font-size: 1rem;
    }

    .custom-arrow-left,
    .custom-arrow-right {
        width: 30px;
        height: 30px;
    }
}
